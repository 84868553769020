import React from "react";
import loadable from "@loadable/component";

const Challenge = loadable(() => import("@organisms/Challenge"));
const StoriesOfChangeList = loadable(() =>
  import("@organisms/StoriesOfChangeList")
);

const StoriesOfChangeSummary = ({ heading, copy, storiesList }) => {
  return (
    <div className="mt-16 md:mt-28">
      <Challenge heading={heading} copy={copy} twoColumns lessBottomSpacing />
      <StoriesOfChangeList list={storiesList} />
    </div>
  );
};

export default StoriesOfChangeSummary;
